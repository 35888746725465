import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import FooterCampaign from './FooterCampaign';

import AppStoreBadge from '../AppStoreBadge/AppStoreBadge';
import PlayStoreBadge from '../PlayStoreBadge/PlayStoreBadge';
import TextLink from '../TextLink/TextLink';
import {
  FacebookIcon,
  TwitterIcon,
  YouTubeIcon,
  InstagramIcon,
} from '~/components/icons/Icons';

import * as css from './Footer.module.scss';

const Footer: FC<{ signUp: boolean }> = ({ signUp }: { signUp: boolean }) => {
  const { allContentfulFooterCampaign, productLinks } = useStaticQuery(graphql`
    query {
      allContentfulFooterCampaign {
        nodes {
          heading
          ctaText
          body {
            body
          }
        }
      }
      productLinks: allContentfulTheBodyCoachProduct(
        filter: { node_locale: { eq: "en-GB" } }
      ) {
        nodes {
          url
          name
        }
      }
    }
  `);

  const footerTopRowItems: Array<{
    sectionName: string;
    items: Array<{ name: string; url: string }>;
  }> = [
    {
      sectionName: 'Products',
      items: productLinks?.nodes,
    },
    {
      sectionName: 'Community',
      items: [
        {
          name: 'Blog',
          url: '/blog',
        },
        {
          name: 'Newsletter',
          url: '/signup',
        },
      ],
    },
    {
      sectionName: 'About Us',
      items: [
        {
          name: 'Joe Wicks',
          url: '/mission',
        },
      ],
    },
    {
      sectionName: 'Contact Us',
      items: [
        {
          name: 'Support Centre',
          url: process.env.SUPPORT_URL || '/support',
        },
        {
          name: 'Careers',
          url: process.env.CAREERS_URL,
        },
        {
          name: 'PR, Media & Partnerships',
          url: '/pr-media-and-partnerships',
        },
      ],
    },
  ];

  const footerBottomRowItems: Array<{ name: string; url: string }> = [
    {
      name: 'Terms & Conditions',
      url: 'https://www.iubenda.com/terms-and-conditions/83525549',
    },
    {
      name: 'Privacy Policy',
      url: 'https://www.iubenda.com/privacy-policy/83525549',
    },
    {
      name: 'Cookie Policy',
      url: 'https://www.iubenda.com/privacy-policy/83525549/cookie-policy',
    },
  ];

  return (
    <div className={css.wrapper}>
      {signUp && allContentfulFooterCampaign?.nodes?.length && (
        <FooterCampaign content={allContentfulFooterCampaign.nodes[0]} />
      )}
      <footer className={css.footer}>
        <div className={css.topRow}>
          {footerTopRowItems.map(({ sectionName, items }) => (
            <div className={css.section} key={sectionName}>
              <h4 className={css.sectionHeading}>{sectionName}</h4>
              <ul>
                {items.map(({ name, url }) => (
                  <li className={`type--body-3 ${css.item}`} key={name}>
                    <TextLink href={url}>{name}</TextLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className={css.section}>
            <div>
              <h4 className={css.sectionHeading}>Get the App</h4>
              <p className={css.appStoreDownload}>
                <AppStoreBadge
                  transparent={true}
                  trackingSuffix="adj_t=yk8ktc1"
                />
                <PlayStoreBadge
                  transparent={true}
                  trackingSuffix="adj_t=yk8ktc1"
                />
              </p>
            </div>
            <ul className={css.socialNetworkList}>
              {[
                {
                  id: 'facebook',
                  url: 'https://www.facebook.com/JoeWicksTheBodyCoach/',
                  Icon: FacebookIcon,
                  ariaLabel: "See Joe Wicks' Facebook page",
                },
                {
                  id: 'twitter',
                  url: 'https://twitter.com/thebodycoach',
                  Icon: TwitterIcon,
                  ariaLabel: "See Joe Wicks' Twitter profile",
                },
                {
                  id: 'YouTube',
                  url: 'https://www.youtube.com/user/thebodycoach1',
                  Icon: YouTubeIcon,
                  ariaLabel: "See Joe Wicks' YouTube Channel",
                },
                {
                  id: 'instagram',
                  url: 'https://www.instagram.com/thebodycoach/',
                  Icon: InstagramIcon,
                  ariaLabel: "See Joe Wicks' Instagram profile",
                },
              ].map(({ id, url, Icon, ariaLabel }) => (
                <li key={id}>
                  <a
                    href={url}
                    aria-label={ariaLabel}
                    className={css.socialLink}
                  >
                    <Icon />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={`type--body-3 ${css.bottomRow}`}>
          <ul>
            <li>&copy; The Body Coach {new Date().getFullYear()}</li>
            {footerBottomRowItems.map(({ name, url }) => (
              <li key={name}>
                <TextLink href={url}>{name}</TextLink>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
