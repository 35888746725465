import React, { FC } from 'react';

import Button from '~/components/button/Button';
import CurvedTop from '~/components/curved-top/CurvedTop';

import * as css from './FooterCampaign.module.scss';

type FooterCampaignProps = {
  content: {
    heading: string;
    ctaText: string;
    body: {
      body: string;
    };
  };
};

const FooterCampaign: FC<FooterCampaignProps> = ({
  content: {
    heading,
    ctaText,
    body: { body },
  },
}) => {
  return (
    <div className={css.wrapper}>
      <CurvedTop className={css.halfPipe} />
      <div className={css.innerWrapper}>
        <h3 className={css.heading}>{heading}</h3>
        <p className={`type--body-2 ${css.body}`}>{body}</p>
        <Button className={css.cta} onClick={() => (location.href = '/signup')}>
          <span>{ctaText}</span>
        </Button>
      </div>
    </div>
  );
};

export default FooterCampaign;
