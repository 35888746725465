import React, { FC } from 'react';
import * as css from './Button.module.scss';

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  large?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  type,
  large = false,
  className,
  disabled,
}) => {
  const props = { onClick, type };

  return (
    <button
      className={`type--action ${css.button} ${large ? css.largeButton : ''} ${
        className ?? ''
      }`}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
