import { Link as GatsbyLink } from 'gatsby';
import React, { FC } from 'react';

const TextLink: FC<{ href: string; children?: React.ReactNode }> = ({
  href,
  children,
}) => (
  <>
    {/* Assume internal links start with exactly one slash */}
    {/^\/(?!\/)/.test(href) ? (
      <GatsbyLink to={href}>{children}</GatsbyLink>
    ) : (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    )}
  </>
);

export default TextLink;
