import React, { FC } from 'react';

import * as css from './Navigation.module.scss';

const Navigation: FC<{ className: string; children?: React.ReactNode }> = ({
  children,
  className,
}) => {
  return (
    <nav>
      <ul className={`type--action ${css.wrapper} ${className ?? ''}`}>
        {children}
      </ul>
    </nav>
  );
};

export default Navigation;
