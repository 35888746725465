// extracted by mini-css-extract-plugin
export var appStoreDownload = "Footer-module--appStoreDownload--6652c";
export var bottomRow = "Footer-module--bottomRow--eec9c";
export var footer = "Footer-module--footer--8932c";
export var item = "Footer-module--item--00a1f";
export var section = "Footer-module--section--c15e1";
export var sectionHeading = "Footer-module--sectionHeading--5fbf8";
export var socialIcons = "Footer-module--socialIcons--9f434";
export var socialLink = "Footer-module--socialLink--613a2";
export var socialNetworkList = "Footer-module--socialNetworkList--7d3cd";
export var topRow = "Footer-module--topRow--5e870";