// extracted by mini-css-extract-plugin
export var col10 = "grid-module--col10--b6e13";
export var col12 = "grid-module--col12--ef8ec";
export var col2 = "grid-module--col2--b9dca";
export var col4 = "grid-module--col4--2b203";
export var col6 = "grid-module--col6--db417";
export var col8 = "grid-module--col8--039e2";
export var hCenter = "grid-module--hCenter--daf95";
export var hiddenXS = "grid-module--hiddenXS--eb7d0";
export var reverseRow = "grid-module--reverseRow--5466a";
export var vCenter = "grid-module--vCenter--0c293";
export var visibleXS = "grid-module--visibleXS--c9410";
export var wrapper = "grid-module--wrapper--3fe7a";