import React, { FC, ReactElement } from 'react';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import * as cssClass from './Layout.module.scss';

import '../../styles/index.scss';

const Layout: FC<{ children?: ReactElement }> = ({
  children,
}: {
  children?: ReactElement;
}) => {
  if (children?.key === '/signup/') {
    return (
      <div className={cssClass.layout}>
        <Header />
        <div>{children}</div>
        <Footer signUp={false} />
      </div>
    );
  } else {
    return (
      <div className={cssClass.layout}>
        <Header />
        <div>{children}</div>
        <Footer signUp={true} />
      </div>
    );
  }
};

export default Layout;
