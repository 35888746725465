import React, { FC } from 'react';
import { SVG } from '../types';

import * as css from './CurvedTop.module.scss';

const CurvedTop: FC<SVG> = ({ className }) => (
  <svg
    width="1440"
    height="94"
    viewBox="0 0 1440 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={`${className} ${css.curvedTop}`}
  >
    <path
      d="M1440 0C1222.87 60.1503 977.813 94 718.466 94C460.339 94 216.367 60.4683 3.31896e-10 0.847694L0 94H1440V0Z"
      fill="currentColor"
    />
  </svg>
);

export default CurvedTop;
