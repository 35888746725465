import React, { FC, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import * as buttonCSS from '../button/Button.module.scss';
import * as navCSS from '~/components/navigation/Navigation.module.scss';
import * as css from './Header.module.scss';
import * as bmCSS from './burger-menu.module.scss';
import * as grid from '~/styles/modules/grid.module.scss';
import Section from '~/components/section/Section';
import Logo from '../logo/Logo';
import Navigation from '../navigation/Navigation';
import * as hamburgersCSS from '~/styles/modules/hamburgers.module.scss';
import { getSubscribeReferralURL } from '~/utils/getSubscribeReferralURL';

const Header: FC = () => {
  const location = useLocation();
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  useEffect(() => {
    if (mobileMenuVisible) {
      setMobileMenuVisible(false);
    }
  }, [location]);

  const NavItems: FC<{ mobile?: boolean }> = ({ mobile }) => (
    <>
      <li className={css.desktopNavItem}>
        <Link
          activeClassName={`${navCSS.activeLink} ${css.activeLink}`}
          to="/mission"
          partiallyActive={true}
        >
          Mission
        </Link>
      </li>
      <li className={css.desktopNavItem}>
        <Link
          activeClassName={`${navCSS.activeLink} ${css.activeLink}`}
          to="/work"
          partiallyActive={true}
        >
          Work
        </Link>
      </li>
      <li className={css.desktopNavItem}>
        <Link
          activeClassName={`${navCSS.activeLink} ${css.activeLink}`}
          to="/blog"
          partiallyActive={true}
        >
          Blog
        </Link>
      </li>
      <li className={css.desktopNavItem}>
        <Link
          activeClassName={`${navCSS.activeLink} ${css.activeLink}`}
          to="/signup"
          partiallyActive={true}
        >
          Newsletter
        </Link>
      </li>
      <li className={css.desktopNavItem}>
        <a href={process.env.SUPPORT_URL} target="_blank" rel="noreferrer">
          Support
        </a>
      </li>
      <li className={css.desktopNavItem}>
        <Link
          activeClassName={`${navCSS.activeLink} ${css.activeLink}`}
          to="/resources"
          partiallyActive={true}
        >
          Resources
        </Link>
      </li>
      {mobile && (
        <>
          <li className={bmCSS.spacerText}>
            <span>Welcome to the app</span>
          </li>
          <li>
            <AnchorLink
              to="/#features"
              onAnchorLinkClick={() => setMobileMenuVisible(false)}
            >
              Features
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="/#pricing"
              onAnchorLinkClick={() => setMobileMenuVisible(false)}
            >
              Pricing &amp; Benefits
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="/#testimonials"
              onAnchorLinkClick={() => setMobileMenuVisible(false)}
            >
              Testimonials
            </AnchorLink>
          </li>
        </>
      )}
    </>
  );

  return (
    <nav className={css.nav}>
      <Section className={css.header}>
        <Navigation className={css.navigation}>
          <li className={`${css.logo} ${mobileMenuVisible ? bmCSS.logo : ''}`}>
            <Link to="/">
              <Logo />
            </Link>
          </li>
          <NavItems />
          <li>
            <a
              className={`type--action ${buttonCSS.button} ${css.getStarted}`}
              href={getSubscribeReferralURL({
                utm_medium: 'universal',
                utm_content: 'nav',
              })}
            >
              <span>Get started</span>
            </a>
          </li>
          <li className={`${css.navBurger}`}>
            <button
              onClick={() => {
                setMobileMenuVisible(!mobileMenuVisible);
              }}
              className={`${hamburgersCSS.hamburger} ${
                hamburgersCSS.hamburgerSpin
              } ${mobileMenuVisible ? hamburgersCSS.isActive : ''}`}
              aria-label={
                mobileMenuVisible
                  ? 'Close website navigation'
                  : 'Open website navigation'
              }
            >
              <span className={hamburgersCSS.box}>
                <span className={hamburgersCSS.inner} />
              </span>
            </button>
          </li>
        </Navigation>
        {/* eslint-disable-next-line */}
        <div
          className={`${bmCSS.wrapper} ${mobileMenuVisible ? bmCSS.open : ''} ${
            grid.visibleXS
          }`}
          onClick={() => {
            setMobileMenuVisible(false);
          }}
        >
          {/* eslint-disable-next-line */}
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Navigation className={bmCSS.inner}>
              <NavItems mobile />
            </Navigation>
          </div>
        </div>
      </Section>
    </nav>
  );
};

export default Header;
