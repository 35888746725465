import React, { FC } from 'react';
import * as css from './Section.module.scss';

interface SectionProps {
  className?: string;
  shape?: React.ReactNode;
  thinner?: boolean;
  children?: React.ReactNode;
}

const Section: FC<SectionProps> = ({ children, className, shape, thinner }) => {
  return (
    <section className={`${css.section} ${className}`}>
      <div className={`${css.inner} ${thinner ? css.thinner : ''}`}>
        <div>{!!children && children}</div>
      </div>
      {!!shape && shape}
    </section>
  );
};

export default Section;
