import React, { FC } from 'react';

import { SVG } from '../types';

export const FacebookIcon: FC<SVG> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M20.27 0H3.158A3.157 3.157 0 000 3.157v17.114a3.157 3.157 0 003.157 3.157h8.44l.015-8.372H9.437a.513.513 0 01-.513-.511l-.01-2.699a.513.513 0 01.512-.515h2.171V8.723c0-3.026 1.849-4.673 4.548-4.673h2.215c.283 0 .513.23.513.513v2.275c0 .284-.23.513-.513.513H17c-1.467 0-1.752.698-1.752 1.722v2.257h3.226c.307 0 .546.269.51.573l-.32 2.699a.513.513 0 01-.51.453h-2.891l-.015 8.372h5.023a3.157 3.157 0 003.157-3.158V3.157A3.157 3.157 0 0020.27 0z"
      fill="currentColor"
    />
  </svg>
);

export const TwitterIcon: FC<SVG> = () => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M27.001 2.946c-.956.413-1.997.71-3.069.825A5.316 5.316 0 0026.276.834a10.52 10.52 0 01-3.381 1.287A5.315 5.315 0 0019 .437a5.327 5.327 0 00-5.328 5.331c0 .413.05.825.131 1.222A15.143 15.143 0 012.817 1.412a5.291 5.291 0 00-.725 2.69c0 1.85.94 3.482 2.375 4.441a5.375 5.375 0 01-2.41-.678v.066a5.33 5.33 0 004.272 5.231c-.458.12-.93.18-1.403.181-.347 0-.675-.034-1.006-.081A5.338 5.338 0 008.9 16.959a10.68 10.68 0 01-6.615 2.278c-.447 0-.86-.016-1.288-.066a15.09 15.09 0 008.184 2.391c9.8 0 15.163-8.119 15.163-15.166 0-.23 0-.462-.016-.693 1.038-.76 1.947-1.7 2.672-2.757z"
      fill="currentColor"
    />
  </svg>
);

export const EmailIcon: FC<SVG> = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M3.578 2.063h17.18c1.182 0 2.148.829 2.148 1.842V14.96c0 1.014-.966 1.843-2.147 1.843H3.579c-1.182 0-2.148-.83-2.148-1.843V3.905c0-1.013.966-1.842 2.147-1.842z"
      stroke="currentColor"
      strokeWidth="2.148"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.685 4.826L12.17 9.893 4.652 4.826"
      stroke="currentColor"
      strokeWidth="2.148"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PrintIcon: FC<SVG> = () => (
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M5.82 8.501V2.052h12.886v6.45"
      stroke="currentColor"
      strokeWidth="2.148"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82 16.793H3.672a2.34 2.34 0 01-1.519-.54c-.402-.345-.629-.814-.629-1.303v-4.606c0-.489.227-.957.63-1.303a2.34 2.34 0 011.518-.54h17.18c.57 0 1.116.195 1.519.54.403.346.629.814.629 1.303v4.606c0 .489-.226.958-.629 1.303a2.34 2.34 0 01-1.519.54h-2.147"
      stroke="currentColor"
      strokeWidth="2.148"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.706 13.107H5.82v7.37h12.886v-7.37z"
      stroke="currentColor"
      strokeWidth="2.148"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const YouTubeIcon: FC<SVG> = () => (
  <svg
    width="35"
    height="24"
    viewBox="0 0 35 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M33.291 3.669A4.263 4.263 0 0030.33.709C27.677 0 17.017 0 17.017 0S6.377-.017 3.697.71A4.256 4.256 0 00.741 3.669a44.278 44.278 0 00-.74 8.24c-.006 2.75.242 5.496.74 8.201a4.28 4.28 0 002.956 2.966c2.652.71 13.32.71 13.32.71s10.634 0 13.312-.71a4.275 4.275 0 002.962-2.966c.488-2.703.727-5.453.71-8.2a44.611 44.611 0 00-.71-8.241zm-19.68 13.333V6.794l8.874 5.115-8.874 5.093z"
      fill="currentColor"
    />
  </svg>
);

export const InstagramIcon: FC<SVG> = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M14.357 2.587c3.834 0 4.288.014 5.802.084 1.4.063 2.16.297 2.665.494.67.26 1.149.572 1.651 1.074.503.502.814.98 1.074 1.65.197.507.43 1.267.495 2.667.069 1.514.083 1.968.083 5.801 0 3.834-.014 4.288-.083 5.802-.064 1.4-.298 2.16-.495 2.666a4.447 4.447 0 01-1.074 1.65 4.45 4.45 0 01-1.65 1.074c-.506.197-1.267.43-2.666.495-1.514.069-1.968.083-5.802.083-3.834 0-4.288-.014-5.801-.083-1.4-.064-2.16-.298-2.666-.495a4.449 4.449 0 01-1.651-1.074 4.448 4.448 0 01-1.074-1.65c-.197-.506-.43-1.267-.494-2.666-.07-1.514-.084-1.968-.084-5.802 0-3.833.014-4.287.084-5.801.063-1.4.297-2.16.494-2.666.26-.67.571-1.149 1.074-1.651.502-.502.98-.814 1.65-1.074.507-.197 1.267-.43 2.667-.494 1.514-.07 1.968-.084 5.801-.084zm0-2.587c-3.9 0-4.388.017-5.92.086C6.91.156 5.867.4 4.954.754A7.037 7.037 0 002.41 2.41 7.037 7.037 0 00.754 4.953C.399 5.866.156 6.91.086 8.438.016 9.969 0 10.458 0 14.358c0 3.898.016 4.387.086 5.918.07 1.529.313 2.572.668 3.486a7.037 7.037 0 001.656 2.542 7.037 7.037 0 002.543 1.656c.913.355 1.956.598 3.485.668 1.531.07 2.02.086 5.919.086 3.9 0 4.388-.016 5.92-.086 1.528-.07 2.571-.313 3.485-.668a7.037 7.037 0 002.542-1.656 7.039 7.039 0 001.656-2.542c.355-.914.598-1.957.668-3.486.07-1.53.086-2.02.086-5.919 0-3.9-.016-4.388-.086-5.92-.07-1.527-.313-2.571-.668-3.484a7.04 7.04 0 00-1.656-2.543A7.038 7.038 0 0023.762.754c-.914-.355-1.957-.598-3.486-.668C18.746.016 18.256 0 14.357 0zm0 6.985a7.373 7.373 0 100 14.745 7.373 7.373 0 000-14.745zm0 12.158a4.786 4.786 0 110-9.572 4.786 4.786 0 010 9.572zm9.387-12.45a1.723 1.723 0 11-3.446 0 1.723 1.723 0 013.446 0z"
      fill="currentColor"
    />
  </svg>
);
